import { ArrowRight } from 'lucide-react'
import ChatbotRequestModal from '.././modals/chatbot-request'
import { ModalTrigger } from '.././ui/animated-modal'
import TextShimmer from '.././ui/text-shimmer'
import { ConfettiButton } from '.././magicui/confetti'
import ActionSelectorModal from '.././modals/action-selector'

export default function HeroCta() {
  return (
    <>
      <ActionSelectorModal>
        <ModalTrigger className="group">
          <ConfettiButton className="transition-all bg-transparent hover:bg-transparent">
            <span
              id="hero-cta-button"
              className="cursor-pointer animate-fade-in relative gap-1 rounded-xl text-white opacity-[0.01] ease-in-out [--animation-delay:300ms] bg-gradient-to-r from-indigo-500 to-sky-500 transition-all hover:from-indigo-600/90 hover:to-sky-600/90 items-center justify-center text-sm md:text-base px-4 md:px-6 flex py-2">
              <TextShimmer className="text-white font-medium">
                Próbáld ki saját chatbotod ingyen
              </TextShimmer>
              <ArrowRight
                className="ml-1 size-4 transition-all duration-300 ease-out group-hover:translate-x-1"
                strokeWidth={2.2}
              />
            </span>
          </ConfettiButton>
        </ModalTrigger>
      </ActionSelectorModal>
      <p className="-mt-1 text-xs animate-fade-in [--animation-delay:300ms] text-slate-400 opacity-[0.01] ease-in-out">
        * Bankkártya megadása nélkül
      </p>
    </>
  )
}
